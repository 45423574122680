import { useEffect } from "react"

const Poczta = () => {
  useEffect(() => {
    window.location.replace(
      "https://h19.microhost.pl/roundcube/"
    )
  })

  return null
}

export default Poczta
